




































import { Component, Mixins, Vue, Ref } from 'vue-property-decorator'
import InputWithLabel from '@/components/molecules/InputWithLabel.vue'
import ButtonBase from '@/components/atoms/v3/ButtonBase.vue'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import jsQR from 'jsqr'

@Component({
  components: {
    InputWithLabel,
    ButtonBase,
  },
})
export default class Login extends Mixins(LoginMethods) {
  private gdlsCode = ''
  private displayQr = false

  private video: any = {}
  private canvasElement: any = document.getElementById('canvas')
  private facing = 'environment'

  private switchCamera() {
    if (this.facing == 'user') {
      this.facing = 'environment'
    } else {
      this.facing = 'user'
    }
    this.turnOffVideo()
    this.facingMode(this.facing)
  }

  private drawLine(begin: { x: any; y: any }, end: { x: any; y: any }, color: any) {
    const canvasElement = document.getElementById('canvas') as HTMLCanvasElement
    const canvas = canvasElement.getContext('2d') as CanvasRenderingContext2D

    canvas.beginPath()
    canvas.moveTo(begin.x, begin.y)
    canvas.lineTo(end.x, end.y)
    canvas.lineWidth = 4
    canvas.strokeStyle = color
    canvas.stroke()
  }

  private facingMode(facing: any) {
    const video = this.video
    const tick = this.tick
    navigator.mediaDevices.getUserMedia({ video: { facingMode: facing } }).then(function (stream) {
      video.srcObject = stream
      video.setAttribute('playsinline', true) // required to tell iOS safari we don't want fullscreen
      video.play()
      requestAnimationFrame(tick)
    })
  }

  private tick() {
    const loadingMessage = document.getElementById('loadingMessage') as HTMLDivElement
    const canvasElement = document.getElementById('canvas') as HTMLCanvasElement
    const outputContainer = document.getElementById('output') as HTMLDivElement
    const canvas = canvasElement.getContext('2d') as CanvasRenderingContext2D
    const outputDataElement = document.getElementById('outputData') as HTMLDivElement
    const outputDataParentElement = outputDataElement.parentElement as HTMLElement

    loadingMessage.innerText = 'Loading video...'
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      loadingMessage.hidden = true
      canvasElement.hidden = false
      outputContainer.hidden = false

      canvasElement.height = this.video.videoHeight
      canvasElement.width = this.video.videoWidth
      canvas.drawImage(this.video, 0, 0, canvasElement.width, canvasElement.height)
      const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height)
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: 'dontInvert',
      })
      if (code) {
        this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, '#FF3B58')
        this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, '#FF3B58')
        this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, '#FF3B58')
        this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, '#FF3B58')
        outputDataParentElement.hidden = false
        this.login(code.data)
        this.turnOffVideo()
      } else {
        outputDataParentElement.hidden = true
      }
    }
    requestAnimationFrame(this.tick)
  }

  private get isYGC() {
    return this.$route.path.includes('/student/sfl/login')
  }

  private created() {
    const pathName = window.location.pathname
    if (pathName === '/student/sozo/login') {
      Vue.prototype.$gdlsCookiesV3.setV3(true, true)
    } else {
      Vue.prototype.$gdlsCookiesV3.setV3(true)
    }
    if (this.isYGC) {
      Vue.prototype.$gdlsCookiesV3.setYGC(true)
    } else {
      Vue.prototype.$gdlsCookiesV3.setYGC(false)
    }
    Vue.prototype.$gdlsCookies.setV2(false)
    this.mode = this.MODE.STUDENT
    this.video = document.createElement('video')
  }

  private async turnOffVideo() {
    const video = this.video
    const mediaStream = video.srcObject
    const tracks = mediaStream.getTracks()
    tracks[0].stop()
    tracks.forEach((track: { stop: () => any }) => {
      track.stop()
      mediaStream.removeTrack(track)
    })
  }

  private async submitLogin() {
    if (this.displayQr === true) {
      this.turnOffVideo()
    }
    this.login(this.gdlsCode)
  }

  private qrLogin() {
    this.displayQr = true
    this.facingMode(this.facing)
  }
}
